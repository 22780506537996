import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Notification",
  "type": "Other"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Notification`}</strong>{` component used to render a notification displayed either as an
absolute positioned box falling from the top viewport or as a static box.`}</p>
    <p>{`Use `}<strong parentName="p">{`Notification`}</strong>{` for server-side errors or messages. The `}<strong parentName="p">{`Notification`}</strong>{` can be
dismissible or not, depending on the use-case.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type={NotificationType.Info} dismissible>
  Example of a Notification component
</Notification>
`}</code></pre>
    <h2>{`Types`}</h2>
    <p>{`There are 4 different notification types :`}</p>
    <ol>
      <li parentName="ol">{`Info`}</li>
      <li parentName="ol">{`Warning`}</li>
      <li parentName="ol">{`Success`}</li>
      <li parentName="ol">{`Error`}</li>
    </ol>
    <p>{`They are all controlled via `}<inlineCode parentName="p">{`type`}</inlineCode>{` prop, which accepts the `}<inlineCode parentName="p">{`NotificationType`}</inlineCode>{` enum.`}</p>
    <h3>{`Info`}</h3>
    <p>{`Use info type to display a generic info message to the user`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type={NotificationType.Info} dismissible>
  A new version of this application is available
</Notification>
`}</code></pre>
    <h3>{`Warning`}</h3>
    <p>{`Use warning notification for notifying the user of potential problems that may affect
his / her work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type={NotificationType.Warning} dismissible>
  Internet connection is slow. Syncing is disabled
</Notification>
`}</code></pre>
    <h3>{`Success`}</h3>
    <p>{`Use success notification for notifying the user of successfully completed action.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type={NotificationType.Success} dismissible>
  Invoice created successfully
</Notification>
`}</code></pre>
    <h3>{`Error`}</h3>
    <p>{`Use error notification for notifying the user of critical error that breaks the user
flow.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type={NotificationType.Error} dismissible>
  Invalid username or password
</Notification>
`}</code></pre>
    <h2>{`Absolute vs static notification boxes`}</h2>
    <p>{`A notification box can be rendered with entering / leaving animations from the top of
the window view port or can be rendered in a simple static block component.`}</p>
    <h3>{`Absolute positioned notification`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`<div style={{ position: "relative", overflow: "hidden", height: "200px" }}>
  <Notification type={NotificationType.Success} dismissible absolute>
    Absolute positioned notification
  </Notification>
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NotificationType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the notification box to be rendered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dismissible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true it will render a close button on the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismiss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback for the close button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`absolute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the box should be absolute and not static`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      